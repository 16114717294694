export default defineNuxtRouteMiddleware(async (to, from) => {

  const user = await getCurrentUser()

  // redirect the user to the login page
  if (!user && to.path == '/helpdesk') {
    return navigateTo({ path: '/login' })
  }
  if (user && to.path == '/login') {
    return navigateTo({ path: '/helpdesk' })
  }
  
})