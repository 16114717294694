import payload_plugin_e0k4tW9bdI from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_4sVQNw7RlN from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_mik7N71GmK from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_auth_plugin_client_cCwkwh1j5u from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/.nuxt/vuefire-plugin.mjs";
import chunk_reload_client_UciE0i6zes from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import firebase_client_zXNDa0wxFH from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/plugins/firebase.client.ts";
import vuetify_7h9QAQEssH from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/plugins/vuetify.ts";
export default [
  payload_plugin_e0k4tW9bdI,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_mik7N71GmK,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  chunk_reload_client_UciE0i6zes,
  firebase_client_zXNDa0wxFH,
  vuetify_7h9QAQEssH
]