import { default as indexW1Jd3uagS6Meta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/about/index.vue?macro=true";
import { default as _91id_93huK2ANr7XjMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/blog/[id].vue?macro=true";
import { default as blog_45gridxs9HGoviCWMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/blog/blog-grid.vue?macro=true";
import { default as indexmHeMAUIudWMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/configuration/index.vue?macro=true";
import { default as indexIJxL5bgSedMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/contact/index.vue?macro=true";
import { default as indexCrtXyJbdmcMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/conference/index.vue?macro=true";
import { default as indexGd9U3bUmzWMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/course/index.vue?macro=true";
import { default as indexvhVkzHxqUwMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/it/index.vue?macro=true";
import { default as indexf3WzmZSfXgMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/medical/index.vue?macro=true";
import { default as indexiPGs7W9Cd4Meta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/nft/index.vue?macro=true";
import { default as indexOUI73qLmuGMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/payment/index.vue?macro=true";
import { default as indexz2BQE1xlwHMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/real-estate/index.vue?macro=true";
import { default as indexrOyG7JUxN7Meta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/sass-demo/index.vue?macro=true";
import { default as indexsnGZK6HQqPMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/travel/index.vue?macro=true";
import { default as index2ks17mRQKpMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/helpdesk/index.vue?macro=true";
import { default as index4lpYiDmTfLMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/index.vue?macro=true";
import { default as indexjyDHOl3Mt1Meta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/login/index.vue?macro=true";
import { default as _91id_9372fUvjXlOmMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/portfolio/[id].vue?macro=true";
import { default as fourcolumsl8iLWPwjwPMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/portfolio/fourcolums.vue?macro=true";
import { default as gridFEwRTX9h89Meta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/portfolio/grid.vue?macro=true";
import { default as threecolumsGxlxqpMOFMMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/portfolio/threecolums.vue?macro=true";
import { default as twocolumsPkcfQxLWqFMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/portfolio/twocolums.vue?macro=true";
import { default as indexXxStftgT2LMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/service/index.vue?macro=true";
import { default as accordionpXiJo2kX3qMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/accordion.vue?macro=true";
import { default as alertVKVVkys81eMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/alert.vue?macro=true";
import { default as avatarxrguT5yQ8QMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/avatar.vue?macro=true";
import { default as chipxKCCI6Ty5sMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/chip.vue?macro=true";
import { default as dialogsVjFdeZWwjhMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/dialogs.vue?macro=true";
import { default as listlyDoweo9LMMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/list.vue?macro=true";
import { default as menus2YSMiXruhRMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/menus.vue?macro=true";
import { default as rattingx9R1bktc35Meta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/ratting.vue?macro=true";
import { default as tabs6H4dEG60V2Meta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/tabs.vue?macro=true";
import { default as tooltipnRjCuUL8hwMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/tooltip.vue?macro=true";
import { default as typography4ziv8JBgsrMeta } from "C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/typography.vue?macro=true";
export default [
  {
    name: indexW1Jd3uagS6Meta?.name ?? "about",
    path: indexW1Jd3uagS6Meta?.path ?? "/about",
    meta: indexW1Jd3uagS6Meta || {},
    alias: indexW1Jd3uagS6Meta?.alias || [],
    redirect: indexW1Jd3uagS6Meta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93huK2ANr7XjMeta?.name ?? "blog-id",
    path: _91id_93huK2ANr7XjMeta?.path ?? "/blog/:id()",
    meta: _91id_93huK2ANr7XjMeta || {},
    alias: _91id_93huK2ANr7XjMeta?.alias || [],
    redirect: _91id_93huK2ANr7XjMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: blog_45gridxs9HGoviCWMeta?.name ?? "blog-blog-grid",
    path: blog_45gridxs9HGoviCWMeta?.path ?? "/blog/blog-grid",
    meta: blog_45gridxs9HGoviCWMeta || {},
    alias: blog_45gridxs9HGoviCWMeta?.alias || [],
    redirect: blog_45gridxs9HGoviCWMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/blog/blog-grid.vue").then(m => m.default || m)
  },
  {
    name: indexmHeMAUIudWMeta?.name ?? "configuration",
    path: indexmHeMAUIudWMeta?.path ?? "/configuration",
    meta: indexmHeMAUIudWMeta || {},
    alias: indexmHeMAUIudWMeta?.alias || [],
    redirect: indexmHeMAUIudWMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/configuration/index.vue").then(m => m.default || m)
  },
  {
    name: indexIJxL5bgSedMeta?.name ?? "contact",
    path: indexIJxL5bgSedMeta?.path ?? "/contact",
    meta: indexIJxL5bgSedMeta || {},
    alias: indexIJxL5bgSedMeta?.alias || [],
    redirect: indexIJxL5bgSedMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexCrtXyJbdmcMeta?.name ?? "demos-conference",
    path: indexCrtXyJbdmcMeta?.path ?? "/demos/conference",
    meta: indexCrtXyJbdmcMeta || {},
    alias: indexCrtXyJbdmcMeta?.alias || [],
    redirect: indexCrtXyJbdmcMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/conference/index.vue").then(m => m.default || m)
  },
  {
    name: indexGd9U3bUmzWMeta?.name ?? "demos-course",
    path: indexGd9U3bUmzWMeta?.path ?? "/demos/course",
    meta: indexGd9U3bUmzWMeta || {},
    alias: indexGd9U3bUmzWMeta?.alias || [],
    redirect: indexGd9U3bUmzWMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/course/index.vue").then(m => m.default || m)
  },
  {
    name: indexvhVkzHxqUwMeta?.name ?? "demos-it",
    path: indexvhVkzHxqUwMeta?.path ?? "/demos/it",
    meta: indexvhVkzHxqUwMeta || {},
    alias: indexvhVkzHxqUwMeta?.alias || [],
    redirect: indexvhVkzHxqUwMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/it/index.vue").then(m => m.default || m)
  },
  {
    name: indexf3WzmZSfXgMeta?.name ?? "demos-medical",
    path: indexf3WzmZSfXgMeta?.path ?? "/demos/medical",
    meta: indexf3WzmZSfXgMeta || {},
    alias: indexf3WzmZSfXgMeta?.alias || [],
    redirect: indexf3WzmZSfXgMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/medical/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPGs7W9Cd4Meta?.name ?? "demos-nft",
    path: indexiPGs7W9Cd4Meta?.path ?? "/demos/nft",
    meta: indexiPGs7W9Cd4Meta || {},
    alias: indexiPGs7W9Cd4Meta?.alias || [],
    redirect: indexiPGs7W9Cd4Meta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/nft/index.vue").then(m => m.default || m)
  },
  {
    name: indexOUI73qLmuGMeta?.name ?? "demos-payment",
    path: indexOUI73qLmuGMeta?.path ?? "/demos/payment",
    meta: indexOUI73qLmuGMeta || {},
    alias: indexOUI73qLmuGMeta?.alias || [],
    redirect: indexOUI73qLmuGMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/payment/index.vue").then(m => m.default || m)
  },
  {
    name: indexz2BQE1xlwHMeta?.name ?? "demos-real-estate",
    path: indexz2BQE1xlwHMeta?.path ?? "/demos/real-estate",
    meta: indexz2BQE1xlwHMeta || {},
    alias: indexz2BQE1xlwHMeta?.alias || [],
    redirect: indexz2BQE1xlwHMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/real-estate/index.vue").then(m => m.default || m)
  },
  {
    name: indexrOyG7JUxN7Meta?.name ?? "demos-sass-demo",
    path: indexrOyG7JUxN7Meta?.path ?? "/demos/sass-demo",
    meta: indexrOyG7JUxN7Meta || {},
    alias: indexrOyG7JUxN7Meta?.alias || [],
    redirect: indexrOyG7JUxN7Meta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/sass-demo/index.vue").then(m => m.default || m)
  },
  {
    name: indexsnGZK6HQqPMeta?.name ?? "demos-travel",
    path: indexsnGZK6HQqPMeta?.path ?? "/demos/travel",
    meta: indexsnGZK6HQqPMeta || {},
    alias: indexsnGZK6HQqPMeta?.alias || [],
    redirect: indexsnGZK6HQqPMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/demos/travel/index.vue").then(m => m.default || m)
  },
  {
    name: index2ks17mRQKpMeta?.name ?? "helpdesk",
    path: index2ks17mRQKpMeta?.path ?? "/helpdesk",
    meta: index2ks17mRQKpMeta || {},
    alias: index2ks17mRQKpMeta?.alias || [],
    redirect: index2ks17mRQKpMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/helpdesk/index.vue").then(m => m.default || m)
  },
  {
    name: index4lpYiDmTfLMeta?.name ?? "index",
    path: index4lpYiDmTfLMeta?.path ?? "/",
    meta: index4lpYiDmTfLMeta || {},
    alias: index4lpYiDmTfLMeta?.alias || [],
    redirect: index4lpYiDmTfLMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjyDHOl3Mt1Meta?.name ?? "login",
    path: indexjyDHOl3Mt1Meta?.path ?? "/login",
    meta: indexjyDHOl3Mt1Meta || {},
    alias: indexjyDHOl3Mt1Meta?.alias || [],
    redirect: indexjyDHOl3Mt1Meta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9372fUvjXlOmMeta?.name ?? "portfolio-id",
    path: _91id_9372fUvjXlOmMeta?.path ?? "/portfolio/:id()",
    meta: _91id_9372fUvjXlOmMeta || {},
    alias: _91id_9372fUvjXlOmMeta?.alias || [],
    redirect: _91id_9372fUvjXlOmMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/portfolio/[id].vue").then(m => m.default || m)
  },
  {
    name: fourcolumsl8iLWPwjwPMeta?.name ?? "portfolio-fourcolums",
    path: fourcolumsl8iLWPwjwPMeta?.path ?? "/portfolio/fourcolums",
    meta: fourcolumsl8iLWPwjwPMeta || {},
    alias: fourcolumsl8iLWPwjwPMeta?.alias || [],
    redirect: fourcolumsl8iLWPwjwPMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/portfolio/fourcolums.vue").then(m => m.default || m)
  },
  {
    name: gridFEwRTX9h89Meta?.name ?? "portfolio-grid",
    path: gridFEwRTX9h89Meta?.path ?? "/portfolio/grid",
    meta: gridFEwRTX9h89Meta || {},
    alias: gridFEwRTX9h89Meta?.alias || [],
    redirect: gridFEwRTX9h89Meta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/portfolio/grid.vue").then(m => m.default || m)
  },
  {
    name: threecolumsGxlxqpMOFMMeta?.name ?? "portfolio-threecolums",
    path: threecolumsGxlxqpMOFMMeta?.path ?? "/portfolio/threecolums",
    meta: threecolumsGxlxqpMOFMMeta || {},
    alias: threecolumsGxlxqpMOFMMeta?.alias || [],
    redirect: threecolumsGxlxqpMOFMMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/portfolio/threecolums.vue").then(m => m.default || m)
  },
  {
    name: twocolumsPkcfQxLWqFMeta?.name ?? "portfolio-twocolums",
    path: twocolumsPkcfQxLWqFMeta?.path ?? "/portfolio/twocolums",
    meta: twocolumsPkcfQxLWqFMeta || {},
    alias: twocolumsPkcfQxLWqFMeta?.alias || [],
    redirect: twocolumsPkcfQxLWqFMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/portfolio/twocolums.vue").then(m => m.default || m)
  },
  {
    name: indexXxStftgT2LMeta?.name ?? "service",
    path: indexXxStftgT2LMeta?.path ?? "/service",
    meta: indexXxStftgT2LMeta || {},
    alias: indexXxStftgT2LMeta?.alias || [],
    redirect: indexXxStftgT2LMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: accordionpXiJo2kX3qMeta?.name ?? "ui-components-accordion",
    path: accordionpXiJo2kX3qMeta?.path ?? "/ui-components/accordion",
    meta: accordionpXiJo2kX3qMeta || {},
    alias: accordionpXiJo2kX3qMeta?.alias || [],
    redirect: accordionpXiJo2kX3qMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/accordion.vue").then(m => m.default || m)
  },
  {
    name: alertVKVVkys81eMeta?.name ?? "ui-components-alert",
    path: alertVKVVkys81eMeta?.path ?? "/ui-components/alert",
    meta: alertVKVVkys81eMeta || {},
    alias: alertVKVVkys81eMeta?.alias || [],
    redirect: alertVKVVkys81eMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/alert.vue").then(m => m.default || m)
  },
  {
    name: avatarxrguT5yQ8QMeta?.name ?? "ui-components-avatar",
    path: avatarxrguT5yQ8QMeta?.path ?? "/ui-components/avatar",
    meta: avatarxrguT5yQ8QMeta || {},
    alias: avatarxrguT5yQ8QMeta?.alias || [],
    redirect: avatarxrguT5yQ8QMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/avatar.vue").then(m => m.default || m)
  },
  {
    name: chipxKCCI6Ty5sMeta?.name ?? "ui-components-chip",
    path: chipxKCCI6Ty5sMeta?.path ?? "/ui-components/chip",
    meta: chipxKCCI6Ty5sMeta || {},
    alias: chipxKCCI6Ty5sMeta?.alias || [],
    redirect: chipxKCCI6Ty5sMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/chip.vue").then(m => m.default || m)
  },
  {
    name: dialogsVjFdeZWwjhMeta?.name ?? "ui-components-dialogs",
    path: dialogsVjFdeZWwjhMeta?.path ?? "/ui-components/dialogs",
    meta: dialogsVjFdeZWwjhMeta || {},
    alias: dialogsVjFdeZWwjhMeta?.alias || [],
    redirect: dialogsVjFdeZWwjhMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/dialogs.vue").then(m => m.default || m)
  },
  {
    name: listlyDoweo9LMMeta?.name ?? "ui-components-list",
    path: listlyDoweo9LMMeta?.path ?? "/ui-components/list",
    meta: listlyDoweo9LMMeta || {},
    alias: listlyDoweo9LMMeta?.alias || [],
    redirect: listlyDoweo9LMMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/list.vue").then(m => m.default || m)
  },
  {
    name: menus2YSMiXruhRMeta?.name ?? "ui-components-menus",
    path: menus2YSMiXruhRMeta?.path ?? "/ui-components/menus",
    meta: menus2YSMiXruhRMeta || {},
    alias: menus2YSMiXruhRMeta?.alias || [],
    redirect: menus2YSMiXruhRMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/menus.vue").then(m => m.default || m)
  },
  {
    name: rattingx9R1bktc35Meta?.name ?? "ui-components-ratting",
    path: rattingx9R1bktc35Meta?.path ?? "/ui-components/ratting",
    meta: rattingx9R1bktc35Meta || {},
    alias: rattingx9R1bktc35Meta?.alias || [],
    redirect: rattingx9R1bktc35Meta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/ratting.vue").then(m => m.default || m)
  },
  {
    name: tabs6H4dEG60V2Meta?.name ?? "ui-components-tabs",
    path: tabs6H4dEG60V2Meta?.path ?? "/ui-components/tabs",
    meta: tabs6H4dEG60V2Meta || {},
    alias: tabs6H4dEG60V2Meta?.alias || [],
    redirect: tabs6H4dEG60V2Meta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/tabs.vue").then(m => m.default || m)
  },
  {
    name: tooltipnRjCuUL8hwMeta?.name ?? "ui-components-tooltip",
    path: tooltipnRjCuUL8hwMeta?.path ?? "/ui-components/tooltip",
    meta: tooltipnRjCuUL8hwMeta || {},
    alias: tooltipnRjCuUL8hwMeta?.alias || [],
    redirect: tooltipnRjCuUL8hwMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/tooltip.vue").then(m => m.default || m)
  },
  {
    name: typography4ziv8JBgsrMeta?.name ?? "ui-components-typography",
    path: typography4ziv8JBgsrMeta?.path ?? "/ui-components/typography",
    meta: typography4ziv8JBgsrMeta || {},
    alias: typography4ziv8JBgsrMeta?.alias || [],
    redirect: typography4ziv8JBgsrMeta?.redirect || undefined,
    component: () => import("C:/hnavarro/Personal/Repository/NCR Business/landing_ncr/pages/ui-components/typography.vue").then(m => m.default || m)
  }
]