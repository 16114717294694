// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
export default defineNuxtPlugin((nuxtApp) => {

    const firebaseConfig = {
        apiKey: "AIzaSyApBePENtUfU3YJnpaMGMIaEIjS8a01kgg",
        authDomain: "helpdesk-ncr.firebaseapp.com",
        projectId: "helpdesk-ncr",
        storageBucket: "helpdesk-ncr.appspot.com",
        messagingSenderId: "993218743795",
        appId: "1:993218743795:web:c71b3cdb60b28615fa1686",
        measurementId: "G-PP01T3KX5X"
      };
      
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const auth = getAuth(app);
      const analytics = getAnalytics(app);
});



  